import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const GridE = ({ data }) => {
  return (
    <div className="grid-layout-wrap grid-e-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.nodes.length === 0
        ? Array.apply(null, Array(8)).map((item, i) => (
            <div className="grid-img-wrap small-grid-card">
              <StaticImage
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/a6c53b90-3258-43ba-be0a-c4b01ebb95cb.png"
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.allMasonContentCreative.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            return (
              <div className="grid-img-wrap small-grid-card">
                <a
                  target="_blank"
                  href={card.meta.url !== null ? card.meta.url : "/"}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default GridE
