import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const GridB = ({ data }) => {
  return (
    <div className="grid-layout-wrap grid-b-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {data.nodes.length === 0
        ? Array.apply(null, Array(5)).map((item, i) => (
            <div
              className={`grid-img-wrap ${
                i == "0" ? "big-grid-card" : "small-grid-card"
              }`}
            >
              <StaticImage
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/431763a5-d442-46d8-877f-fae4bd7e79eb.png"
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.allMasonContentCreative.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            return (
              <div
                className={`grid-img-wrap ${
                  position !== "0" ? "small-grid-card" : "big-grid-card"
                }`}
              >
                <a
                  target="_blank"
                  href={card.meta.url !== null ? card.meta.url : "/"}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="banner image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default GridB
