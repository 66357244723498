import React from "react"

const GridC = ({ small, medium }) => {
  return (
    <div className="grid-layout-wrap grid-c-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {small.nodes.length === 0
        ? Array.apply(null, Array(4)).map((item, i) => (
            <div className="grid-img-wrap small-grid-card">
              <img
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/c36428b1-2e0c-4524-baac-b40026caa49e.png"
                alt="product image"
              />
            </div>
          ))
        : small.distinct.map(position => {
            let card = small.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            return (
              <div className="grid-img-wrap small-grid-card">
                <a
                  target="_blank"
                  href={card.meta.url !== null ? card.meta.url : "/"}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="grid image"
                  />
                </a>
              </div>
            )
          })}
      {medium.nodes.length === 0
        ? Array.apply(null, Array(2)).map((item, i) => (
            <div className="grid-img-wrap medium-grid-card">
              <img
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/cd103eda-af3d-418c-838a-058e54873038.png"
                alt="product image"
              />
            </div>
          ))
        : medium.distinct.map(position => {
            let card = medium.nodes.filter(
              elem => elem.meta.order === position
            )[0]
            return (
              <div className="grid-img-wrap medium-grid-card">
                <a
                  target="_blank"
                  href={card.meta.url !== null ? card.meta.url : "/"}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="grid image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default GridC
