import React from "react"
import Slideshow from "../SlideShow/Slideshow"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridA = ({ slides, products }) => {
  return (
    <div className="grid-layout-wrap grid-a-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {products.nodes.length === 0
        ? Array.apply(null, Array(4)).map((item, i) => (
            <div className="grid-img-wrap small-grid-card">
              <img
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/5f605ad6-5912-4588-aad5-c929c330f61a.png"
                alt="product image"
              />
            </div>
          ))
        : products.distinct.map(position => {
            let card = products.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            return (
              <div className="grid-img-wrap small-grid-card">
                <a
                  target="_blank"
                  href={card.meta.url !== null ? card.meta.url : "/"}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
      <div className="relative grid-img-wrap big-grid-card">
        <Slideshow data={slides} />
      </div>
    </div>
  )
}

export default GridA
